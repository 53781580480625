import { ref, onMounted } from 'vue'


const onlineCasinos = () => {  //This object encompassed the app setup
 // These consts are the redeclaration of data() using the Options API
const items = ref([]) // ref)= gives reactivity, No  need to use 'this'.
const noOpenRel = 'nofollow noindex noopener'
const blankTarget = '_blank'
const selected = ref('selected')

onMounted(async () => { // This replaces mounted:

   try {  // try and catch replace then(response) and then(error)

     //const response = await fetch('/wp-content/uploads/vue/juegos/assets/apostala_spanish_ARS_slug.json')
     const response = await fetch('https://api.baserow.io/api/database/rows/table/346872/?user_field_names=true',
       {headers: {Authorization: 'Token L7sDLQZSZqG4LHKpwN08YF7JcXe8g3jA'}})
     const res = await response.json()
     let nextPage = res.next
     items.value = res.results
     let count = 1
     while (nextPage) {
       count++
       let fetchUrl = 'https://api.baserow.io/api/database/rows/table/346872/?user_field_names=true&page=' + count.toString()
       let nresponse = await fetch(fetchUrl,{headers: {Authorization: 'Token L7sDLQZSZqG4LHKpwN08YF7JcXe8g3jA'}})
       let next_ = await nresponse.json()
       items.value = [...items.value, ...next_.results]
       //console.log('Results are  '+ items.value.length);
       nextPage = next_.next
     }
     console.log(items.value)
   } catch (error) {
     console.log(error)
   }
})
// Here we finally return every element
return { items, noOpenRel, blankTarget, selected }
}
export{ onlineCasinos}